.pay-card{
    transition: opacity 0.5s;
    position: relative;
    margin-top: 0.5rem ;
}



.pay-header{
    color: var(--color1);
    background-color: var(--color4);
    height: 30px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    opacity: 0.8;
}

.pay-header:hover{
    opacity: 1;
}


.pay-body{
    background-color: var(--color1);
    max-height: 700px;
    overflow: hidden;
    transition: max-height 1s;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 10;
    width: 100%;
}

.pay-body span{
    overflow: hidden;
}

.zero-height{
    max-height: 0;
}

.pay-body input{
  border: 1px dashed rgb(132, 132, 132);
  border-radius: 10px;
  outline: none;
}

.paidFors{
    max-width: 400px;
    margin: 0 auto;
}

.on , .off  {  
    border-radius: 15px;
    height: 40px;
    cursor: pointer;
    margin-bottom: 12px;
    font-size: 14px;
    padding-top: 5px;
    overflow: hidden;
}

.on{
    border: 4px solid lightseagreen;
    background-color: azure;
    padding-bottom: 5px;
}

.off{
    border: 1px solid rgb(104, 104, 104);
    color: #6b6b6b;
    padding: 8px;
}

  
.hezar{
    font-size: 12px;
    padding-right: 6px;
    text-align: right;
    margin-top: 8px;  
}

.unequal input{
    font-size: 14px;
    
}



.pay-card .delete-icon{
    position: absolute;
    top:5px;
    left: 5px;
    font-size: 12px;
    height: 20px;
    width: 20px;
    color: var(--color1);
    border: 2px solid var(--color1);
    border-radius: 10px;
    transition: all 0.4s;
    cursor: pointer;
}

.pay-card .delete-icon:hover{
    color: var(--color-error);
    border-color:  var(--color-error)
}

.unequal span{
    overflow: hidden;
}

.pay-toggle-left {
    border-left: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-left: 1px solid #6b6b6b;
}

.pay-toggle-right {
    border-right: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}



@media (min-width:768px){
   .pay-body{
    position: absolute ;
    top: 30px ;
    z-index: 110;
   }
   
}

@media (max-width:768px){
    .pay-body{
        width:94% ;
        margin: 0 auto;
    }
}
