.people-card{
    background-color: var(--color1);
    padding-top: 12px;
    height: 200px ;
    margin-bottom: 0;
    transition: all 1.5s;
    opacity: 0.85;
    border-radius: 16px;
  }

  .people-card:hover{
    opacity: 1;
  }

  
.people-card input{
    background: transparent;
    font-size: 18px;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

.people-card input:hover , .people-card input:focus{
    border: 1px dashed #777;
    padding-top: 4px;
    padding-bottom: 4px;
}


.error-border{
    border: 1px dashed var(--color-error);
    padding: 3px !important;
}

.motherpay-text {
    margin-top: 4px;
    margin-bottom: 13px;
    font-size: 20px;
}

.people-card .delete-icon{
    position: absolute;
    left: 6px ;
    top:8px ;
    border-radius: 13px;
    cursor: pointer;
    border: 2px solid grey;
    width: 26px;
    height: 26px;
    color: gray;
    transition: all 0.2s;
    
}

.people-card .delete-icon:hover{
color: var(--color-error);
border-color:  var(--color-error);
}


.people-card button{
width: 80%;
margin: 6px;
}
