.small-header,.larg-header{
  color: var(--color1)
}

.larg-header{
    height: 60px;
}

.larg-header h1{
  font-family: titraj;
  margin-bottom: 0;
  font-size: 34px;
}

.larg-header h2{
  font-size: 16px;
}

.larg-header img{
    width: 100px;
    margin-bottom: 32px;
}

.header-text{
  display: inline-block;
  text-align: right;
  margin-right: 6px ;
}

.small-header{
  text-align: right;
  margin-right: 2vw;
}

.small-header img{
  width: 44px;
}

.small-header h1{
  display: inline-block;
  margin-right: 1vw;
  font-family: titraj;
  font-size: 28px;

}