
.addPeoplePannel{
    background-color: #2C3333;
    color: var(--color1);
    margin: 4rem auto 1rem;
    border-radius: 20px;
    width:96vw;
    min-width: 300px;
    max-width: 650px;
    transition: all 1s;
}

.addPeoplePannel .input-container{
    display: flex;
    padding: 1px 2vw;
}

.addPeoplePannel input{
    font-size: 18px;
    border-radius: 6px;
    flex-grow: 1;
}



.logo img{
    width: 100px;
}
.logo{
    margin-top: 20vh;
    color: var(--color1);
}

.logo h1{
    font-family: titraj,vazir;
    font-size: 40px;
}

.logo h2{
    font-size: 20px;
}

.start-tag{
    position: relative;
    top:-5px;
    font-weight: bold;
}