
.result-card{
    background-color:var(--color1);
    white-space:normal;
    line-height: 1.8;
    padding: 1rem 0;
  }
  
  .result-card p{
    text-align: right;
    margin-right: 8px;
    margin-bottom: 0;
  }

 

.result-header{
    background-color: var(--color4);
    color: azure;
    border-radius: 6px;
}

.minus::before{
  content: '-';
  display: inline-block;
  font-size: 24px;
  color: red;
  position: relative;
  top: 4px;
  margin-left: 6px;
}


.plus::before{
  content: '+';
  display: inline-block;
  font-size: 24px;
  color: blue;
  position: relative;
  top: 4px;
  margin-left: 6px;
}

.nopay::before{
  content: '=';
  display: inline-block;
  font-size: 24px ;
  position: relative;
  top: 4px;
  margin-left: 4px;
  
}

